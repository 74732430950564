<template>
  <div class="box-bottom">
    <el-row class="fath100">
      <el-col :span="24" class="fath100">
        <div ref="divMyChart" class="fath100">
          <div :id="redId" class="myChart" :style="`height:${Math.floor(myChartHei)}px;width:${myChartWid}px`" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  props: {
    redId: {// 唯一标识，多个的时候需要
      type: String,
      default() {
        return 'myChart'
      }
    },
    option: {// 表格类型,数据
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      myChartWid: '345',
      myChartHei: '360',
      myChart:null,
    }
  },
  mounted() {
    this.getDomW().then(() => {
      this.drawLine()// 初始化
      // console.log(this.myChartWid, '====', this.myChartHei)
    })
  },
  methods: {
    getDomW() {
      return new Promise((resolve) => {
        this.$nextTick(() => { // 父元素的宽度
          this.myChartWid = this.$refs.divMyChart.clientWidth
          // setTimeout(() => {
          this.myChartHei = this.$refs.divMyChart.clientHeight
          // console.log(this.myChartWid, '====', this.$refs.divMyChart.clientHeight)
          resolve(this.myChartWid, this.myChartHei)
          // }, 0)
        })
      })
    },
    drawLine() {
      const chartDom = document.getElementById(this.redId)
      this.myChart = echarts.init(chartDom)
      let option_ = {}
      if (this.option.series) {
        option_ = this.option
      } else {
        // console.log('进入');
        // option_ = {
        //   xAxis: {
        //     type: 'category',
        //     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        //   },
        //   yAxis: {
        //     type: 'value'
        //   },
        //   series: [
        //     {
        //       data: [820, 932, 901, 934, 1290, 1330, 1320],
        //       type: 'line',
        //       smooth: true
        //     }
        //   ]
        // };
      }
      option_ && this.myChart.setOption(option_)
    },
    setOption(option){
      this.myChart && this.myChart.setOption(option)
    }
  }
}
</script>
<style scoped  lang="scss">
  .box-bottom,.fath100{
    height: 100%;
  }
  .myChart{
    border-top: 0px;
  }
</style>
