<template>
  <div class="box">
    <div class="cname">{{cname}}</div>
    <div :class="['flex',{'selelct':isSwitch}]">
      <!-- <div v-for="item in forArr" @click="selelctId(item)" :key="item.id" :class="[{'act_class': String(item.id) === actId, 'item-class': true}, act_class]""> -->
      <div v-for="item in forArr" @click="selelctId(item)" :key="item.id"
        :class="String(item.id)===actId?`item-class ${act_class}`:'act_class item-class'">
        {{ item.name }}
      </div>
    </div>
    <div class="zname" @click="selelct()" v-if="noIsSwitch==='false'">
      <template v-if="!isSwitch">
        展开<i style="margin-left:5px" class="el-icon-arrow-down" />
      </template>
      <template v-else>
        收起<i style="margin-left:5px" class="el-icon-arrow-up" />
      </template>

    </div>
  </div>
</template>
<script>
export default {
  name: 'classify',
  props: {
    forArr: {
      type: Array,
      default: () => []
    },
    cname: {
      type: String,
      default: ''
    },
    actId: {
      type: String,
      default: '-1' //全部
    },
    noIsSwitch: {
      type: String,
      default: 'true'
    },
    act_class: {
      type: String,
      default: 'act'
    }
  },
  data() {
    return {
      isSwitch: false
    }
  },
  methods: {
    selelct() {
      //这个地方用变化高度来隐藏
      this.isSwitch = !this.isSwitch
    },
    selelctId(item) {
      //给他爹，找第二级
      this.$emit('selelctId', item)
    }
  },
  created() {
    // console.log(this.act_class);
  }
}
</script>
<style lang='scss' scoped>
.box {
  font-size: 14px;
  color: #181f2d;
  display: flex;
  padding: 10px 20px;
  height: auto;
  border-bottom: 1px dashed #dcdfe8;
  margin-top: 10px;
}
.cname {
  display: inline-block;
  width: 50px;
  margin-top: 5px;
}
.zname {
  cursor: pointer;
  display: inline-block;
  color: #315ccf;
  width: 75px;
  margin-top: 5px;
}
.flex {
  padding: 0 20px 5px 0;
  display: flex;
  height: 35px;
  overflow: hidden;
  flex-wrap: wrap;
  width: 975px;
}
.selelct {
  height: auto !important;
}
.item-class {
  margin: 0 15px;
  padding: 0 5px;
  color: #536387;
  height: 25px;
  margin-bottom: 15px;
  cursor: pointer;
  line-height: 25px;
}
.act {
  color: #ffffff;
  background-color: #315ccf !important;
}
.act_2 {
  color: #ffffff;
  background-color: #7aca5a !important;
}
</style>